import React, {useState} from 'react';
import styles from './IngredientElement.module.scss';
import Delete from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";

const IngredientElement = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const { t } = useTranslation();

    const handleAddOrSubButton = (value) =>{
        const event = {
            target: {
                name: 'quantity',
                value: Number(props.ingredient.quantity) + value
            }
        }
        props.handleChange(event, props.index)
    }

    const editIngredient = (
        <div className={styles.editIngredient} onClick={(ev) => ev.stopPropagation()}>
            <TextField id="standard-basic" label={t("recipe.unit")} name="unit" value={props.ingredient.unit} onChange={(event) => props.handleChange(event, props.index)} />
            <div className={styles.quantity}>
                <button onClick={() => handleAddOrSubButton(-1)}>-</button>
                <TextField id="standard-basic" label={t("recipe.quantity")} name="quantity" value={props.ingredient.quantity} onChange={(event) => props.handleChange(event, props.index)} />
                <button onClick={() => handleAddOrSubButton(1)}>+</button>
            </div>
        </div>
    )

    return (
        <div className={styles.ingredientElementContainer} onClick={() => setIsFocus(!isFocus)}>
            <div className={styles.info}>
                <span className={styles.name}>{props.ingredient.item.name}</span>
                <span className={styles.quantity}>{props.ingredient.quantity + (props.ingredient.unit === "" ? "" : " " + props.ingredient.unit)}</span>
                <Delete className={styles.deleteIcon} onClick={() => props.deleteIngredient(props.index)}/>
            </div>

            {isFocus ? editIngredient :  null}
        </div>
    )
};

export default IngredientElement;