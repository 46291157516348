import { OPEN, CLOSE } from "./drawer.types";

const INITIAL_STATE = {
    isDrawerOpen: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN:
            return {
                ...state, isDrawerOpen: true,
            };

        case CLOSE:
            return {
                ...state, isDrawerOpen: false,
            };
        default: return state
    }
}

export default reducer;