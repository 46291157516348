import React from 'react';
import styles from './Footer.module.scss';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const Footer = (props) => {

    const { t } = useTranslation();

    const onCreateListClick = () => {
        props.createGroceryList();
    }

    return (
        <div className={styles.footerContainer}>
            <div className={styles.fade}>

            </div>
            <div className={styles.content}>
                <Button variant="contained" onClick={onCreateListClick}>
                    {t("footer.createList")} <span className={styles.number}>{props.selectedRecipeList.length}</span>
                </Button>
            </div>
        </div>
    )
}

export default Footer;