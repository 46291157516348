import React from "react";
import styles from './StepTwo.module.scss';
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IngredientElement from "./IngredientElement";

const filter = createFilterOptions();

const StepTwo = (props) => {

    const handleIngredientAutocompleteChange = (ev, value) => {
        props.addIngredient(value);
    }

    return (
        <div className={styles.stepTwoContainer}>
            <h2>Ingrédients</h2>
            <span className={styles.subtitle}>Ajoutez les ingrédients nécessaire pour la recette.</span>
            <div className={styles.ingredientList}>
                {props.ingredientList.map((ingredient, i) => (
                    <IngredientElement key={i} ingredient={ingredient}  index={i} handleChange={props.handleIngredientChange} deleteIngredient={props.deleteIngredient}/>
                ))}
            </div>
            <div>
                <Autocomplete
                    className={styles.autoComplete}
                    onChange={
                        (event, value) => handleIngredientAutocompleteChange(event, value)
                    }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                name: `Add "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={props.fetchedIngredients}
                    getOptionLabel={(option) => {
                        if (typeof option.name === 'string') {
                            return option.name;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.name;
                    }}
                    renderOption={(option) => option.name}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Ajouter un ingrédient" variant="outlined" />}
                />
            </div>
        </div>
    );
}

export default StepTwo;