import styles from "./RecipeList.module.scss";
import RecipeCard from "./RecipeCard";
import React, {useEffect, useState} from "react";
import Footer from "./Footer";
import CloseIcon from '@material-ui/icons/Close';

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RecipeList = (props) => {

    const db = props.firebase.database();
    const history = useHistory();

    const [recipeList, setRecipeList] = useState([]);
    const [selectedRecipeList, setSelectedRecipeList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        db.ref('users/' + props.firebase.auth().currentUser.uid + "/recipes").on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const item = e.val();
                const id = e.key;
                response.push({id, ...item})
            })
            setRecipeList(response ?? []);
        })

        db.ref('users/' + props.firebase.auth().currentUser.uid + "/selected").on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const item = e.val();
                const id = e.key;
                response.push({id, ...item})
            })
            setSelectedRecipeList(response ?? []);
        })
    }, [setRecipeList, setSelectedRecipeList, db, props.firebase])

    const addRecipeToList = (recipe) => {
        db.ref('users/' + props.firebase.auth().currentUser.uid + "/selected").set([...selectedRecipeList, recipe]);
        setSelectedRecipeList([...selectedRecipeList, recipe]);
    }

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
    }

    const removeFromSelectedRecipeList = (recipe) => {
        const newList = selectedRecipeList.filter(x => {
            return x.id !== recipe.id;
        })
        db.ref('users/' + props.firebase.auth().currentUser.uid + "/selected").set(newList);
        setSelectedRecipeList(newList);
    }

    const createGroceryList = async () => {
        var groceryIngredientList = [];
        selectedRecipeList.forEach(recipe => {
            for (const id in recipe.ingredients) {
                groceryIngredientList.push({ id: id, ...recipe.ingredients[id]})
            }
        });

        var grouped = groceryIngredientList.reduce( (r, a) => {
            r[a.id] = r[a.id] || [];
            r[a.id].push(a);
            return r;
        }, {});

        var finalGroceryIngredientList = [];

        for (const ingredient in grouped) {
            var quantity = {};
            const name = grouped[ingredient][0].name;
            const order = grouped[ingredient][0].order;
            for (const el of grouped[ingredient]) {
                const unitName = el.measurementUnit === "" ? "unit" : el.measurementUnit;
                if(quantity[unitName]) {
                    quantity[unitName] += Number(el.quantity);
                } else {
                    quantity[unitName] = Number(el.quantity);
                }
            }
            finalGroceryIngredientList.push({name, order, quantity});
        }
        const groceryId = Date.now();
        await db.ref('users/' + props.firebase.auth().currentUser.uid + "/groceryLists/" + groceryId).set(finalGroceryIngredientList);
        history.push("/groceries/" + groceryId)
    }

    return (
        <div className={styles.recipeContainer}>
            <div className={styles.recipeSearch}>
                <input placeholder={t('recipe.search-for-recipe')} onChange={handleSearchChange} value={searchValue}/>
                { searchValue === "" ? null :  <CloseIcon className={styles.closeIcon} onClick={() => setSearchValue("")}/>}

            </div>
            <div className={styles.recipeGrid}>
                {
                    recipeList.filter(el => el.name.toLowerCase().includes(searchValue.toLowerCase())).map((recipe, index) => (
                        <RecipeCard recipe={recipe} addToList={addRecipeToList} removeFromList={removeFromSelectedRecipeList} isSelected={selectedRecipeList.find(el => el.id === recipe.id) ? true : false} key={index} />
                    ))
                }
            </div>

            {
                selectedRecipeList.length > 0 ? <Footer selectedRecipeList={selectedRecipeList} createGroceryList={createGroceryList} /> : null
            }
        </div>
    )
}

export default RecipeList;