import React from 'react';
import styles from './GroceryCard.module.scss';
import Card from "../../../components/Cards";
import moment from "moment";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const GroceryCard = (props) => {
    const { t } = useTranslation()
    const url = (props.isArchived ? "/groceries/archived/" : "/groceries/") + props.grocery.id
    return (
        <Link to={url}>
            <div className={styles.groceryCardContainer}>
                <Card>
                    <div className={styles.insideCard}>
                        <h3>{`${t("grocery.list-from")} ${moment(Number(props.grocery.id)).format('MMMM DD')}`}</h3>
                        <span>{`${t("grocery.created-at")} ${moment(Number(props.grocery.id)).format('HH:mm')}`}</span>
                    </div>
                </Card>
            </div>
        </Link>
    )
}

export default GroceryCard;