import React from 'react';
import styles from './Home.module.scss';
import RecipeList from "./RecipeList";

const Home = (props) => {
    return (
        <div className={styles.homeContainer}>
            <RecipeList firebase={props.firebase}/>
        </div>
    )
}

export default Home;