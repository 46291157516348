import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import menu from './menu';
import { useTranslation } from "react-i18next";
import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux"
import { closeDrawer, openDrawer } from "../../redux/Drawer/drawer.actions";
import { Link } from "react-router-dom";

const Drawer = (props) => {
    const { t } = useTranslation();

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(props.isOpen) {
            props.closeDrawer();
        } else {
            props.openDrawer();
        }
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menu.map((item) => (
                    <Link to={item.url} key={t(item.name)}>
                        <ListItem button>
                            <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
                            <ListItemText primary={t(item.name)} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    return (
        <React.Fragment >
            <SwipeableDrawer
                anchor={"left"}
                open={props.isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list("left")}
            </SwipeableDrawer>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        isOpen: state.drawer.isDrawerOpen,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => dispatch(openDrawer()),

        closeDrawer: () => dispatch(closeDrawer()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);