import React from 'react';
import styles from './Footer.module.scss';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const Footer = (props) => {
    const { t } = useTranslation()
    return (
        <div className={styles.footerContainer}>
            <Button type="submit">{t("utils.create")}</Button>
        </div>
    )
}

export default Footer;