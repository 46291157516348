import React from 'react';
import styles from './Recipe.module.scss';
import {
    useParams
} from "react-router-dom";

const Recipe = (props) => {
    const { recipeId } = useParams();

    console.log(recipeId);

    return (
        <div className={styles.recipeContainer}>

        </div>
    )
}

export default Recipe;