import React, { useEffect, useState } from "react";
import styles from './Grocery.module.scss'
import { useHistory, useParams } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from "react-i18next";


const Grocery = (props) => {
    const { groceryId } = useParams();
    const db = props.firebase.database();
    const [grocery, setGrocery] = useState();
    const [dragId, setDragId] = useState();
    const isArchived = props.archived ?? false;
    const url = isArchived ? 'users/' + props.firebase.auth().currentUser.uid + "/archived/groceryLists/" + groceryId : 'users/' + props.firebase.auth().currentUser.uid + "/groceryLists/" + groceryId;
    const history = useHistory()
    const { t } = useTranslation();

    useEffect(() => {
        db.ref(url).on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const items = e.val();
                const id = e.key;
                response.push({ id, items })
            });
            setGrocery(response ?? [])
        });
    }, [db, setGrocery, props.firebase, groceryId, url]);

    const handleChange = (event) => {
        db.ref(url + "/" + event.target.id + "/isSelected").set(event.target.checked);
    }

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const getQuantityAsString = (quantity) => {
        const unit = Object.keys(quantity);
        var string = "";

        unit.forEach(x => {
            if (quantity[x] !== "" && quantity[x] !== 0) {
                string += `${quantity[x]}${x === "unit" ? "" : " " + x} `
            }
        });
        return string;
    }

    const CheckBox = ({ item }) => (
        <div
            className={styles.ingredientContainer}
            draggable={true}
            id={item.id}
            onDragStart={handleDrag}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={handleDrop}>
            <span className={styles.name}>{item.items.name}</span>
            <span className={styles.quantity}>{getQuantityAsString(item.items.quantity)}</span>
            <Checkbox
                checked={item.items.isSelected}
                id={item.id}
                onChange={handleChange}
                name="checkedB"
                color="primary"
                className={styles.checkbox}
            />
        </div>
    );

    const handleDrop = async (ev) => {
        ev.target.style.backgroundColor = null;

        const dragIngredient = grocery.find((ingredient) => ingredient.id === dragId);
        const dropIngredient = grocery.find((ingredient) => ingredient.id === ev.currentTarget.id);

        const dragIngredientOrder = dragIngredient.items.order;
        const dropIngredientOrder = dropIngredient.items.order;

        const url = 'users/' + props.firebase.auth().currentUser.uid + "/groceryLists/" + groceryId + "/"

        const newIngredientState = grocery.map((ingredient) => {
            if (ingredient.id === dragId) {
                ingredient.items.order = dropIngredientOrder;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref(url + ingredient.id).update({ order: dropIngredientOrder });
            } else if (ingredient.items.order > dragIngredientOrder && ingredient.items.order < dropIngredientOrder) {
                ingredient.items.order = ingredient.order - 1;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref(url + ingredient.id).update({ order: ingredient.order - 1 });
            } else if (ingredient.items.order < dragIngredientOrder && ingredient.items.order >= dropIngredientOrder) {
                ingredient.items.order = ingredient.items.order + 1;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref(url + ingredient.id).update({ order: ingredient.items.order + 1 });
            }
            return ingredient;
        });

        setGrocery(newIngredientState);
    };

    const onDragOver = (event) => {
        event.preventDefault()
        event.target.style.backgroundColor = "#A3C5E7";
        event.target.style.paddingTop = "48px";
    }

    const onDragLeave = (event) => {
        event.preventDefault()
        event.target.style.backgroundColor = null;
        event.target.style.paddingTop = "4px";
    }

    const onArchiveClicked = async () => {
        const url = 'users/' + props.firebase.auth().currentUser.uid + "/groceryLists/" + groceryId;
        const archiveUrl = 'users/' + props.firebase.auth().currentUser.uid + "/archived/groceryLists/" + groceryId;
        const snapshot = await db.ref(url).once('value');
        await db.ref(archiveUrl).set(snapshot.val());
        await db.ref(url).set({});
        history.push('/groceries');
    }

    const onUnarchiveClicked = async () => {
        const url = 'users/' + props.firebase.auth().currentUser.uid + "/groceryLists/" + groceryId;
        const archiveUrl = 'users/' + props.firebase.auth().currentUser.uid + "/archived/groceryLists/" + groceryId;
        const snapshot = await db.ref(archiveUrl).once('value');
        await db.ref(url).set(snapshot.val());
        await db.ref(archiveUrl).set({});
        history.push('/groceries');
    }

    const onDeleteClicked = async () => {
        const archiveUrl = 'users/' + props.firebase.auth().currentUser.uid + "/archived/groceryLists/" + groceryId;
        await db.ref(archiveUrl).set({});
        history.push('/groceries');
    }

    return (
        <div className={styles.groceryContainer}>
            <section>
                <div className={styles.header}>
                    <div className={styles.text}>
                        <h2 className={styles.fasteryHeader}>{t('grocery.ingredients')}</h2>
                        <span className={styles.fasterySubtitle}>{t('grocery.ingredients-to-buy')}</span>
                    </div>
                    <div className={styles.button}>
                        {
                            isArchived ? (
                                <div>
                                    <IconButton aria-label="unarchive" color="primaty" onClick={onUnarchiveClicked}>
                                        <UnarchiveIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="secondary" onClick={onDeleteClicked}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ) : 
                                <IconButton aria-label="archive" color="secondary" onClick={onArchiveClicked}>
                                    <ArchiveIcon />
                                </IconButton>
                            
                        }
                    
                    </div>
                </div>
                <div className={styles.list}>
                    {grocery?.sort((a, b) => a.items.order > b?.items.order).map((el, index) => !el.items.isSelected ?
                        <CheckBox
                            key={index}
                            item={el} />
                        : null)}
                </div>
            </section>
            <section>
                <h2 className={styles.fasteryHeader}>{t('grocery.already-in-cart')}</h2>
                <span className={styles.fasterySubtitle}>{t('grocery.ingredient-in-possession')}</span>
                <div className={styles.list}>
                    {grocery?.sort((a, b) => a?.items.order > b?.items.order).map((el, index) => el.items.isSelected ? <CheckBox key={index} item={el} /> : null)}
                </div>
            </section>


        </div >
    )
};

export default Grocery;