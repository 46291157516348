import React from 'react';
import styles from './RecipeCard.module.scss';
import Card from "../../../../components/Cards";

const minutesToHoursAndMinutes = (time) => {
    let string = "";
    const hours = parseInt((time/60).toString());
    if(hours > 0) {
        string += `${hours}h `
    }
    string += `${time%60}m`

    return string
}

const RecipeCard = (props) => {

    const onAddButtonClick = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if(props.isSelected) {
            props.removeFromList(props.recipe);
            return;
        } else {
            props.addToList(props.recipe);
        }
    }

    return (
            <div className={styles.recipeCardContainer} onClick={onAddButtonClick}>
                <Card isSelected={props.isSelected}>
                    <div className={styles.insideCard} >
                        <div className={styles.imgContainer}>
                            <img src={props.recipe.coverUrl !== "" ? props.recipe.coverUrl : "https://static.vecteezy.com/system/resources/previews/000/463/565/original/healthy-food-clipart-vector.jpg"} alt={props.recipe.name} />
                        </div>
                        <div className={styles.text}>
                            <h2>{props.recipe.name}</h2>
                            <span className={styles.time}>{minutesToHoursAndMinutes(props.recipe.time)}</span>
                        </div>
                    </div>
                </Card>

            </div>
    )
}

export default RecipeCard;