import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from '@react-firebase/auth';
import Home from './pages/Home';
import Login from "./pages/Login";
import {FirebaseDatabaseProvider} from "@react-firebase/database";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Recipe from "./pages/Recipie";
import CreateRecipe from "./pages/Recipie/CreateRecipe";
import GroceryLists from "./pages/GroceryLists";
import Grocery from "./pages/Grocery";
import NavBar from "./components/NavBar";
import Drawer from "./components/Drawer";
import Ingredients from "./pages/Ingredients";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const App = () => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>
          <IfFirebaseAuthed>
            <Router>
              <NavBar/>
              <Drawer />
              <Switch>
                <Route path="/ingredients">
                  <Ingredients firebase={firebase}/>
                </Route>
                <Route path="/recipes/create">
                  <CreateRecipe firebase={firebase}/>
                </Route>
                <Route path="/recipes/:recipeId">
                  <Recipe firebase={firebase}/>
                </Route>
                <Route path="/groceries/archived/:groceryId">
                  <Grocery archived={true} firebase={firebase}/>
                </Route>
                <Route path="/groceries/archived">
                  <GroceryLists archived={true} firebase={firebase}/>
                </Route>
                <Route path="/groceries/:groceryId">
                  <Grocery firebase={firebase}/>
                </Route>
                <Route path="/groceries">
                  <GroceryLists firebase={firebase}/>
                </Route>
                <Route path="/">
                  <Home firebase={firebase}/>
                </Route>
              </Switch>
            </Router>
          </IfFirebaseAuthed>
          <IfFirebaseUnAuthed>
            <Login firebase={firebase} />
          </IfFirebaseUnAuthed>
        </FirebaseDatabaseProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
