import React, {useState} from 'react';
import styles from './StepOne.module.scss';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {Fab} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { CircularProgress } from '@material-ui/core';

const StepOne = (props) => {
    const { t } = useTranslation();
    const [isImgUploading, setIsImgUploading] = useState(false);

    const imgSelectorClick = () => {
        document.getElementById("input").click();
    }

    const fileAdded = async (event) => {
        setIsImgUploading(true);
        await props.fileAdded(event.target.files[0]);
        setIsImgUploading(false);
    }

    return (
        <div className={styles.stepOneContainer}>
            <h2>Informations de base</h2>
            <span className={styles.subtitle}>Veuillez remplir tout les champs avant de passer à la prochaine étape</span>
            <form autoComplete="off" onSubmit={props.nextStep}>
                <TextField className={styles.textField} required id="standard-basic" label={t("recipe.recipeName")} name="name" value={props.recipeState.name} onChange={props.handleChange} />
                <TextField className={styles.textField} required id="standard-basic" label={t("utils.description")} name="description" value={props.recipeState.description} onChange={props.handleChange} />
                <TextField className={styles.textField} required id="standard-basic" label={t("recipe.time")} name="time" value={props.recipeState.time} onChange={props.handleChange} />
                <div className={styles.img} onClick={imgSelectorClick}>
                    { isImgUploading ? <CircularProgress /> : (
                        props.recipeState.coverUrl === "" ? <span>Veuillez sélectionner une photo</span> : <div><img className={styles.coverImage} src={props.recipeState.coverUrl} alt="recipe-cover" /></div>
                    )}
                </div>
                <input id="input" className={styles.input} type='file' onChange={fileAdded} />
                <Fab aria-label="like" type="submit" className={styles.nextStepButton}>
                    <ArrowForwardIcon />
                </Fab>
            </form>
        </div>
    )
}

export default StepOne;