import { OPEN, CLOSE } from "./drawer.types";

export const openDrawer = () => {
    return {
        type: OPEN,
    };
};

export const closeDrawer = () => {
    return {
        type: CLOSE,
    };
};