const menu = [
    {
        name: "drawer.home",
        url: "/",
        icon: "home"
    },
    {
        name: "drawer.grocery-list",
        url: "/groceries",
        icon: "local_grocery_store"
    },
    {
        name: "drawer.recipes",
        url: "/recipes",
        icon: "menu_book"
    },
    {
        name: "drawer.ingredients",
        url: "/ingredients",
        icon: "local_pizza"
    }
]

export default menu;