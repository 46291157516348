import React from 'react';
import styles from './Login.module.scss';
import Button from '@material-ui/core/Button';

const Login = (props) => {
    const loginWithGoogle = () => {
        const googleAuthProvider = new props.firebase.auth.GoogleAuthProvider();
            props.firebase.auth().signInWithPopup(googleAuthProvider);
    }
    
    return (
        <div className={styles.loginContainer}>
            <Button variant="contained" onClick={loginWithGoogle}>Login with Google</Button>
        </div>
    );
}

export default Login;