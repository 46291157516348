import React, {useEffect, useState} from 'react';
import styles from './CreateRecipe.module.scss';
import Footer from "./Footer";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import {useHistory} from "react-router-dom";

const CreateRecipe = (props) => {
    const [ingredientList, setIngredientList] = useState([]);
    const [recipeState, setRecipeState] = useState({name: "", description: "", time: "", coverUrl: ""})
    const [fetchedIngredients, setFetchedIngredients] = useState([]);
    const [step, setStep] = useState(1);
    const db = props.firebase.database();
    const storage = props.firebase.storage();
    const history = useHistory();

    useEffect(() => {
        db.ref("ingredients").on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const item = e.val();
                const id = e.key;
                response.push({id, ...item})
            })
            setFetchedIngredients(response ?? [])
        })
    }, [setFetchedIngredients, db])

    const handleChange = (ev) => {
        const name = ev.target.name;
        setRecipeState({...recipeState, [name]: ev.target.value});
    }

    const handleIngredientChange = (ev, index) => {
        let tmpList = [...ingredientList];
        const name = ev.target.name;
        tmpList[index][name] = ev.target.value;
        setIngredientList(tmpList);
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        var finalRecipe = {...recipeState};
        var ingredients = {};
        var finalIngredientList = ingredientList;
        if(finalIngredientList[finalIngredientList.length - 1].quantity === "") {
            finalIngredientList.pop();
        }
        finalIngredientList.forEach(el => {
            ingredients[el.item.id] = {quantity: el.quantity, measurementUnit: el.unit, name: el.item.name, order: el.item.order}
        });

        finalRecipe.ingredients = ingredients;
        const recipeId = await db.ref("users/" + props.firebase.auth().currentUser.uid + '/recipes').push(finalRecipe).getKey();
        history.push('/recipes/'+ recipeId)
    }

    const fileAdded = async (file) => {
        const snapshot = await storage.ref('recipes/' + Date.now()).put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();
        setRecipeState({...recipeState, coverUrl: downloadURL});
    }

    const deleteIngredient = (index) => {
        let tmpList = [...ingredientList];
        tmpList.splice(index, 1);
        setIngredientList(tmpList);

    }

    const stepSwitcher = () => {
        switch (step) {
            case 1:
                return (<StepOne recipeState={recipeState} handleChange={handleChange} fileAdded={fileAdded} handleSubmit={handleSubmit} nextStep={nextStep}/>);
            case 2:
                return (<StepTwo fetchedIngredients={fetchedIngredients} ingredientList={ingredientList} addIngredient={addIngredient} handleIngredientChange={handleIngredientChange} deleteIngredient={deleteIngredient}/>);
            default:
                return null;
        }
    }

    const addIngredient = async (newIngredient) => {
        if(!newIngredient) {
            return;
        }
        if (typeof newIngredient === 'string') {
            setIngredientList([...ingredientList, {item: newIngredient, quantity: "0", unit: ""}]);
        } else if (newIngredient && newIngredient.inputValue) {
            const myNewValue = newIngredient.inputValue;
            const ref = db.ref("ingredients");
            var id;
            await ref.transaction( (firebaseIngredientList) => {
                var current;
                try {
                    current = Object.keys(firebaseIngredientList)?.length ?? 0;
                } catch {
                    current = 0;
                }
                
                const newIngredient = {name: myNewValue, order: current};
                id = ref.push(newIngredient).getKey();
            });
            setIngredientList([...ingredientList, {item: {name: myNewValue, id}, quantity: "0", unit: ""}]);
        } else {
            setIngredientList([...ingredientList, {item: newIngredient, quantity: "0", unit: ""}]);
        }
    }

    return (
        <div className={styles.createRecipeContainer}>
            { stepSwitcher() }
            <form autoComplete="off" onSubmit={handleSubmit}>
                {
                    ingredientList.length > 0 ?
                        <Footer ingredientList={ingredientList} /> :
                        null
                }
            </form>
        </div>
    )
}

export default CreateRecipe;