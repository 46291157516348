import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Ingredients.module.scss';

const Ingredients = (props) => {
    const [ingredients, setIngredients] = useState([]);
    const [dragId, setDragId] = useState();
    const { t } = useTranslation();

    const db = props.firebase.database();

    useEffect(() => {
        db.ref('ingredients/').on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const item = e.val();
                const id = e.key;
                response.push({ id, ...item })
            })

            setIngredients(response)
        })
    }, [setIngredients, db]);

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = async (ev) => {
        ev.target.style.height = "8px";
        ev.target.style.backgroundColor = null;

        const dragIngredient = ingredients.find((ingredient) => ingredient.id === dragId);
        const dropIngredient = ingredients.find((ingredient) => ingredient.id === ev.currentTarget.id);

        const dragIngredientOrder = dragIngredient.order;
        const dropIngredientOrder = dropIngredient.order;

        const newIngredientState = ingredients.map((ingredient) => {
            if (ingredient.id === dragId) {
                ingredient.order = dropIngredientOrder > dragIngredientOrder ? dropIngredientOrder - 1 : dropIngredientOrder;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref('/ingredients/' + ingredient.id).set(tmpIngredient);
            } else if (ingredient.order > dragIngredientOrder && ingredient.order < dropIngredientOrder) {
                ingredient.order = ingredient.order - 1;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref('/ingredients/' + ingredient.id).set(tmpIngredient);
            } else if (ingredient.order < dragIngredientOrder && ingredient.order >= dropIngredientOrder) {
                ingredient.order = ingredient.order + 1;
                const tmpIngredient = { ...ingredient };
                tmpIngredient.id = null;
                db.ref('/ingredients/' + ingredient.id).set(tmpIngredient);
            }
            return ingredient;
        });

        setIngredients(newIngredientState);
    };

    const onDragOver = (event) => {
        event.preventDefault()
        event.target.style.backgroundColor = "#A3C5E7";
        event.target.style.height = "20px";
    }

    const onDragLeave = (event) => {
        event.preventDefault();
        event.target.style.height = "8px";
        event.target.style.backgroundColor = null;
    }


    return (
        <div className={styles.ingredientsContainer}>
            <h2>{t('ingredient.order')}</h2>
            <span className={styles.subtitle}>{t('ingredient.reorder')}</span>
            {ingredients.sort((a, b) => a.order > b.order).map((ingredient, index) =>
                <div key={index}>
                    <div
                        className={styles.droppable}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        id={ingredient.id}
                        onDrop={handleDrop}>

                    </div>
                    <div
                        key={index}
                        draggable={true}
                        id={ingredient.id}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={handleDrag}
                        className={styles.ingredientElement}>
                        <span className={styles.index}>
                            {ingredient.order + 1}
                        </span>
                        <span className={styles.name}>
                            {ingredient.name}
                        </span>

                    </div>
                </div>

            )}
        </div>
    )
}

export default Ingredients;