import React, {useEffect, useState} from 'react';
import styles from './GroceryLists.module.scss';
import GroceryCard from "./GroceryCard";
import { useTranslation } from 'react-i18next';

const GroceryLists = (props) => {
    const { t } = useTranslation();
    const [groceryLists, setGroceryLists] = useState([]);
    const db = props.firebase.database();
    const isArchived = props.archived ?? false;

    useEffect(() => {
        const url = isArchived ? 'users/' + props.firebase.auth().currentUser.uid + "/archived/groceryLists/" : 'users/' + props.firebase.auth().currentUser.uid + "/groceryLists"
        db.ref(url).on('value', (snapshot) => {
            const response = [];
            snapshot.forEach((e) => {
                const items = e.val();
                const id = e.key;
                response.push({id, items})
            });

            response.sort((a, b) => {
                return b.id - a.id;
            })
            setGroceryLists(response ?? [])
        });
    }, [setGroceryLists, db, props.firebase, isArchived])
    return (
        <div className={styles.groceryListsContainer}>
            {groceryLists && groceryLists.length > 0 ?
                groceryLists.map((el, index) => <GroceryCard grocery={el} key={index} isArchived={isArchived} />) :
                <span className={styles.emptySubtitle}>{t('grocery.no-list-to-show')}</span>
            }
        </div>
    )
};

export default GroceryLists;