import React from "react";
import styles from './NavBar.module.scss';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import {connect} from "react-redux";
import {openDrawer} from "../../redux/Drawer/drawer.actions";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from "react-router-dom";

const NavBar = (props) => {
    const history = useHistory()

    const onAddButtonClick = () => {
        history.push("/recipes/create");
    }

    return (
        <div className={styles.navBarContainer}>
            <IconButton aria-label="menu" onClick={props.openDrawer}>
                <MenuIcon />
            </IconButton>

            <IconButton aria-label="menu" onClick={onAddButtonClick}>
                <AddIcon />
            </IconButton>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => dispatch(openDrawer())
    }
}

export default connect(null, mapDispatchToProps)(NavBar);