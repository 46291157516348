import React from 'react';
import styles from './Cards.module.scss';

const Card = (props) => {
    return (
        <div className={`${styles.cardContainer} ${props.isSelected ? styles.selected: null}`}>
            {props.children}
        </div>
    )
}

export default Card;